.my_attendance {
  width: calc(100% - 246px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .title_wrapper {
    height: 44px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
  }

  .header_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
  }

  .legend_wrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .filter_wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    :global(.shopee-react-date-picker__input .shopee-react-input__inner) {
      height: auto;
    }
    :global(.shopee-react-date-picker) {
      display: block;
    }
    :global(.shopee-react-date-picker__input) {
      width: 100%;
    }
  }

  .filter_label {
    font-size: 14px;
    margin-right: 8px;
  }

  .filter_component {
    width: 200px;
    &.date_picker {
      width: 210px;
    }
  }
  .filter_action {
    display: flex;
    align-items: center;
  }
  .submit_button {
    margin-right: 8px;
  }

  .export_wrapper {
    padding: 8px 16px;
    display: flex;
    justify-content: end;
  }
  
}
