// 主容器
.chart_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  font-size: 12px;
}

// Loading
.chart_loading {
  margin: 64px auto;
}

// 头部区域
.header_fixed {
  position: sticky;
  top: 0;
  z-index: 99;
  background: #fff;
  width: 100%;
  display: flex;
  height: 70px;
}

.header_holder {
  width: 160px;
  height: 40px;
  flex-shrink: 0;
}

.month_holder {
  width: 160px;
  height: 40px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.day_holder {
  width: 160px;
  height: 30px;
  border-bottom: 1px solid #d9d9d9;
}

.month_and_day {
  flex: 1;
}

.month_header {
  display: flex;
  height: 40px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.month_cell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #666;
}

.day_header {
  display: flex;
  height: 30px;
  border-bottom: 1px solid #d9d9d9;
}

.day_cell {
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #666;
}

// 内容区域
.content_wrapper {
  display: flex;
  flex: 1;
  position: relative;
  margin-top: -70px;
  padding-top: 70px;
}

// 名字列表
.names_container {
  position: sticky;
  left: 0;
  top: 70px;
  width: 160px;
  flex-shrink: 0;
  z-index: 2;
  background: #fff;
}

.name_item {
  display: flex;
  align-items: center;
  height: 36px;
  font-size: 12px;
  color: #333;
  padding: 12px 12px;
  border-right: 1px solid #dedede;

  .staff_name {
    width: 116px;
    margin-right: 4px;
  }

  a {
    color: inherit;
  }

  em {
    max-width: 116px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.exempt_icon {
  position: relative;
  left: 8px;
  cursor: pointer;

  &_disable {
    cursor: not-allowed;
  }
}

.bottom_border {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  z-index: 1;
  border-bottom: 1px solid #d9d9d9;
}

// 图表区域
.chart_content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chart_container {
  min-height: 360px;
}

.chart_overflow {
  flex: 1;
  overflow: visible;
}