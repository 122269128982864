.staff_attendance {
  width: calc(100% - 246px);
  display: flex;
  flex-direction: column;



  .title_wrapper {
    height: 44px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
  }

  .header_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
  }

  .legend_wrapper {
    display: flex;
    align-items: center;
  }

  .filter_wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    :global(.shopee-react-date-picker__input .shopee-react-input__inner) {
      height: auto;
    }
    :global(.shopee-react-date-picker) {
      display: block;
    }
    :global(.shopee-react-date-picker__input) {
      width: 100%;
    }
  }

  .filter_label {
    font-size: 14px;
    margin-right: 8px;
  }

  .filter_component {
    width: 200px;
    &.date_picker {
      width: 210px;
    }
  }
  .submit_button {
    margin-right: 8px;
  }

  .export_wrapper {
    padding: 8px 16px;
    display: flex;
    justify-content: end;
  }
  .pagination {
    padding: 24px;
    display: flex;
    justify-content: end;
    align-items: center;
    border-top: 1px solid #eee;
  }
}


.range_item {
  display: inline-block;
  height: 26px;
  padding: 0 8px;
  line-height: 26px;
  margin: 0 2px;
  font-size: 12px;
  font-weight: 700;
  color: #5c5c5c;
  border-radius: 16px;
  cursor: pointer;
  &:hover,
  &.selected {
    color: #ffffff;
    background-color: #403b36;
  }
  :global(.shopee-react-input__inner--small .shopee-react-input__input) {
    height: 28px;
  }
}