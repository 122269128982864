
.legend_outter_wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  .label {
    margin-left: 4px;
    font-size: 14px;
    color: #ADB2BA;
  }

  margin-right: 24px;
}

.legend_inner_wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    background-color: #fff;
    font-style: normal;
    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      .missing {
        display: flex;
        margin-right: 12px;
        width: 12px;
        height: 12px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 12px;

        
        &.red {
          background: rgba(245, 34, 45, 0.75);
        }
        &.orange {
          background: #f5881b;
        }
        &.lightBlue {
          background: #6ec7ff;
        }
        &.blue {
          background: #1b92f5;
        }
        &.darkBlue {
          background: #005ac0;
        }
      }
      .edit {
        width: 10px;
        height: 10px;
        margin-right: 12px;
      }
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000d26;
      }
      .iconText {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #adb2ba;
      }
    }

  }

  